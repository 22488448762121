import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.ContactFilter
): AppEnv.ContactRequest => ({
  about: filter.about ? {value: filter.about, type: 'like'} : undefined,
  chatId: filter.chatId ? {value: filter.chatId} : undefined,
  email: filter.email ? {value: filter.email, type: 'like'} : undefined,
  fullname: filter.fullname
    ? {value: filter.fullname, type: 'like'}
    : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  phones: filter.phones ? {value: filter.phones, type: 'like'} : undefined,
  username: filter.username
    ? {value: filter.username, type: 'like'}
    : undefined,
  website: filter.website ? {value: filter.website, type: 'like'} : undefined
});

export default filterToRequest;

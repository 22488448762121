import React, {useState} from 'react';
import {Button, TableCell, TableRow, Text} from 'whatcrm-core';
import {Link} from 'react-router-dom';

import {Tag} from 'components';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import LabelDelete from './label-delete/label-delete';
import LabelUpdate from './label-update/label-update';

interface Label {
  label: AppEnv.LabelExpand;
}

const Label = ({label}: Label) => {
  const {crmList} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);

  const {integration} = label;
  const crm = crmList.find(item => item.id == integration.integration_type_id);
  const isActive = isConfirmActive || isUpdateActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        <Tag color={label.color}>{label.tag}</Tag>
      </TableCell>

      <TableCell>{label.chatId}</TableCell>

      <TableCell>
        <Link
          to={`/integrations?filter_domain=${integration.domain}&filter_integration_type_id=${integration.integration_type_id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent" isLink>
            {integration.domain}
          </Button>
        </Link>

        <Text isParagraph size="s">
          {crm?.title}
        </Text>
      </TableCell>

      <LabelUpdate
        isUpdateActive={isUpdateActive}
        label={label}
        setIsUpdateActive={setIsUpdateActive}
      />

      <LabelDelete
        isConfirmActive={isConfirmActive}
        label={label}
        setIsConfirmActive={setIsConfirmActive}
      />
    </TableRow>
  );
};

export default Label;

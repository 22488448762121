import React from 'react';
import {Button, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {LabelForm} from 'pages/labels/labels-content/components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useLabelsContext} from 'pages/labels/labels-context';
import * as AppEnv from 'app-env';

interface LabelUpdate {
  isUpdateActive: boolean;
  label: AppEnv.LabelExpand;
  setIsUpdateActive: AppEnv.SetState<boolean>;
}

const LabelUpdate = ({
  isUpdateActive,
  label,
  setIsUpdateActive
}: LabelUpdate) => {
  const {pushNotification} = useAppContext();
  const {setLabelList} = useLabelsContext();

  const {putLabels} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.LabelShape>) => {
    const {data} = await putLabels(label.id, values);
    if (!data) return true;

    pushNotification(t`Label updated`);
    setIsUpdateActive(false);

    setLabelList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);
      if (index == undefined || index < 0) return prevValue;

      return update(prevValue, {[index]: {$set: data}});
    });

    return true;
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsUpdateActive(true)}
        tip={t`Edit`}
      >
        <Icons.Edit />
      </Button>

      <LabelForm
        initialValues={label}
        isActive={isUpdateActive}
        isUpdate
        onSubmit={handleSubmit}
        label={label}
        setIsActive={setIsUpdateActive}
      />
    </TableCell>
  );
};

export default LabelUpdate;

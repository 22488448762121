import React from 'react';
import {Textarea, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const About = () => {
  const [field, , helpers] = useField<string>('about');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Comment`}</Title.H3>

      <Textarea
        {...field}
        onChange={helpers.setValue}
        placeholder={t`Comment`}
        width="max"
      />
    </div>
  );
};

export default About;

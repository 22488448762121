import React from 'react';
import {Input, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Tag = () => {
  const [field, meta, helpers] = useField<string>('tag');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Tag`}</Title.H3>

      <Input
        {...field}
        isValid={isValid}
        onChange={helpers.setValue}
        placeholder={t`Tag`}
        width="max"
      />
    </div>
  );
};

export default Tag;

import React, {useState} from 'react';
import {Button, TableCell, TableRow, Text} from 'whatcrm-core';
import {Link} from 'react-router-dom';

import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import RoleDelete from './role-delete/role-delete';
import RoleUpdate from './role-update/role-update';

interface Rate {
  role: AppEnv.RoleExpand;
}

const Role = ({role}: Rate) => {
  const {crmList} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);

  const {integration} = role;
  const crm = crmList.find(item => item.id == integration.integration_type_id);
  const isActive = isConfirmActive || isUpdateActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{role.title}</TableCell>

      <TableCell>
        <Link
          to={`/integrations?filter_domain=${integration.domain}&filter_integration_type_id=${integration.integration_type_id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent" isLink>
            {integration.domain}
          </Button>
        </Link>

        <Text isParagraph size="s">
          {crm?.title}
        </Text>
      </TableCell>

      <RoleUpdate
        isUpdateActive={isUpdateActive}
        role={role}
        setIsUpdateActive={setIsUpdateActive}
      />

      <RoleDelete
        isConfirmActive={isConfirmActive}
        role={role}
        setIsConfirmActive={setIsConfirmActive}
      />
    </TableRow>
  );
};

export default Role;

import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import filterToRequest from './filter-to-request';
import HomeContext from './home-context';

const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [partnerList, setPartnerList] = useState<AppEnv.Partner[]>();

  const {fetchPartners} = useRequest();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.PartnerFilter> = useFilter();

  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));
  usePaginationParam(pagination.currentPage);

  const getPartnerList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchPartners<AppEnv.Partner[]>({
      filter: filterToRequest(filter),
      page: pagination.currentPage
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setIsLoading(false);
    setPartnerList(data || []);
  };

  useEffect(() => {
    getPartnerList();
  }, [filter, pagination.currentPage]);

  useEffect(() => {
    document.title = `${t`Partners`} - Whatcrm Console`;
  }, []);

  return (
    <>
      <HomeContext.Provider
        value={{isFilterActive, partnerList, setIsFilterActive, setPartnerList}}
      >
        <Content pagination={pagination} />
        <Filter filter={filter} setFilter={setFilter} />
      </HomeContext.Provider>

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default Home;

import React from 'react';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import {useRolesContext} from '../roles-context';
import * as AppEnv from 'app-env';

import Domain from './domain/domain';
import Title from './title/title';

interface Filter {
  filter: AppEnv.RoleFilter;
  setFilter: AppEnv.SetState<AppEnv.RoleFilter>;
}

interface Values extends Partial<AppEnv.RoleShape> {
  domain?: string;
}

const RolesFilter = ({filter, setFilter}: Filter) => {
  const {isFilterActive, roleList, setIsFilterActive} = useRolesContext();
  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: Values) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={{}}
      isActive={isFilterActive}
      isDisabled={!roleList}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
      onSubmit={handleSubmit}
    >
      <Title />
      <Domain />
    </Sidebar>
  );
};

export default RolesFilter;

import React from 'react';
import {EmptyBox, Pagination, Preloader, Wrapper} from 'whatcrm-core';

import {EntriesNumber} from 'components';
import {useRolesContext} from '../roles-context';

import RoleList from './role-list/role-list';
import RolesHeader from './roles-header/roles-header';

const RolesContent = () => {
  const {pagination, roleList} = useRolesContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <RolesHeader />

        {roleList ? (
          roleList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <RoleList />
              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default RolesContent;

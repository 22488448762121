import React from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useRolesContext} from 'pages/roles/roles-context';
import * as AppEnv from 'app-env';

import RolesCreate from './roles-create/roles-create';

interface State {
  referrer?: string;
}

const RolesHeader = () => {
  const {isFilterActive, setIsFilterActive} = useRolesContext();

  const {state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  return (
    <>
      {state?.referrer && (
        <Link to={state.referrer}>
          <Button color="white">
            <Icons.AngleLeft />
            {t`Back`}
          </Button>
        </Link>
      )}

      <Title.H1>{t`Roles`}</Title.H1>

      <Wrapper gap={8}>
        <RolesCreate />

        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>
      </Wrapper>
    </>
  );
};

export default RolesHeader;

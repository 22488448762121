import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface LabelsContext {
  getLabelList: () => Promise<void>;
  isFilterActive: boolean;
  labelList: AppEnv.LabelExpand[] | undefined;
  pagination: CoreEnv.Pagination;
  setIsFilterActive: AppEnv.SetState<boolean>;
  setLabelList: AppEnv.SetState<AppEnv.LabelExpand[] | undefined>;
}

const LabelsContext = React.createContext<LabelsContext>({} as LabelsContext);

export const useLabelsContext = () => useContext(LabelsContext);
export default LabelsContext;

import React from 'react';
import {Input, Title as CoreTitle} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Title = () => {
  const [field, , helpers] = useField<string | undefined>('title');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Name`}</CoreTitle.H3>
      <Input {...field} onChange={helpers.setValue} placeholder={t`Name`} />
    </div>
  );
};

export default Title;

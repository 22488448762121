import React, {useState} from 'react';
import {Button, TableCell, TableRow, Text} from 'whatcrm-core';
import {formatPhoneNumber} from 'whatcrm-core';
import {Link} from 'react-router-dom';

import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import ContactDelete from './contact-delete/contact-delete';
import ContactUpdate from './contact-update/contact-update';

interface Contact {
  contact: AppEnv.Contact;
}

const Contact = ({contact}: Contact) => {
  const {crmList} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);

  const {integration} = contact;
  const crm = crmList.find(item => item.id == integration.integration_type_id);
  const isActive = isConfirmActive || isUpdateActive;
  const phones: string[] = JSON.parse(contact.phones || '[]');

  return (
    <TableRow isActive={isActive}>
      <TableCell>{contact.fullname}</TableCell>

      <TableCell>
        {phones.map((phone, i) => (
          <Text color="dark" isParagraph key={i} whiteSpace="nowrap">
            {formatPhoneNumber(phone)}
          </Text>
        ))}
      </TableCell>

      <TableCell>{contact.username && `@${contact.username}`}</TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableCell>{contact.website}</TableCell>
      <TableCell>{contact.about}</TableCell>

      <TableCell>
        {Object.entries(contact).map(([key, value]) => (
          <>
            {key.startsWith('utm') && value && (
              <Text color="dark" isParagraph key={key} whiteSpace="nowrap">
                {key}
                {'='}
                {value}
              </Text>
            )}
          </>
        ))}
      </TableCell>

      <TableCell>{contact.chatId}</TableCell>

      <TableCell>
        <Link
          to={`/integrations?filter_domain=${integration.domain}&filter_integration_type_id=${integration.integration_type_id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent" isLink>
            {integration.domain}
          </Button>
        </Link>

        <Text isParagraph size="s">
          {crm?.title}
        </Text>
      </TableCell>

      <ContactUpdate
        contact={contact}
        isUpdateActive={isUpdateActive}
        setIsUpdateActive={setIsUpdateActive}
      />

      <ContactDelete
        contact={contact}
        isConfirmActive={isConfirmActive}
        setIsConfirmActive={setIsConfirmActive}
      />
    </TableRow>
  );
};

export default Contact;

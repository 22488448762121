import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import upd from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useClientsContext} from '../../../clients-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import DatePartner from './date-partner/date-partner';

interface ClientForm {
  client: AppEnv.Integration;
  isClientFormActive: boolean;
  setIsClientFormActive: AppEnv.SetState<boolean>;
}

interface Values {
  date_partner: number | null;
}

const validationSchema = yup
  .object()
  .shape({date_partner: yup.number().required().positive()});

const ClientForm = ({
  client,
  isClientFormActive,
  setIsClientFormActive
}: ClientForm) => {
  const {pushNotification} = useAppContext();
  const {setClientList} = useClientsContext();

  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const {data} = await putIntegrations(client.id, values);
    if (!data) return;

    pushNotification(client.domain, t`Client updated.`);

    setClientList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == client.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {$set: data}});
    });

    setIsClientFormActive(false);

    return true;
  };

  const initialValues: Values = {date_partner: client.date_partner};

  return (
    <Popup
      isActive={isClientFormActive}
      onClick={() => setIsClientFormActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Edit the client`}</Title.H2>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <DatePartner />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsClientFormActive(false)}>
              {t`Close`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default ClientForm;

import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useContactsContext} from 'pages/contacts/contacts-context';
import Contact from './contact/contact';

const ContactList = () => {
  const {contactList} = useContactsContext();
  const {t} = useTranslation();

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{t`Name`}</TableCell>
        <TableCell>{t`Phone numbers`}</TableCell>
        <TableCell>{t`Username`}</TableCell>
        <TableCell>{t`Email`}</TableCell>
        <TableCell>{t`Website`}</TableCell>
        <TableCell>{t`Comment`}</TableCell>
        <TableCell>{'UTM'}</TableCell>
        <TableCell>{t`Chat ID`}</TableCell>
        <TableCell>{t`Integration`}</TableCell>
        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {contactList?.map(contact => (
          <Contact contact={contact} key={contact.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ContactList;

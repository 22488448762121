import React from 'react';
import {Title, Radio} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Version = () => {
  const [field, , helpers] = useField('version');
  const {t} = useTranslation();

  const options = [
    {label: t`Any version`, value: '-1'},
    {label: 'WhatsApp', value: 'whatcrm'},
    {label: 'WhatsApp Cloud API', value: 'cloud'},
    {label: 'Telegram', value: 'telegram'},
    {label: t`Avito`, value: 'avito'},
    {label: 'Chat', value: 'chat'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Version`}</Title.H3>

      <Radio {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default Version;

import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface ContactsContext {
  contactList: AppEnv.Contact[] | undefined;
  getContactList: () => Promise<void>;
  isFilterActive: boolean;
  pagination: CoreEnv.Pagination;
  setContactList: AppEnv.SetState<AppEnv.Contact[] | undefined>;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const ContactsContext = React.createContext<ContactsContext>(
  {} as ContactsContext
);

export const useContactsContext = () => useContext(ContactsContext);
export default ContactsContext;

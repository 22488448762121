import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect} from 'components';
import * as AppEnv from 'app-env';

interface IntegrationId {
  label: Partial<AppEnv.LabelExpand> | undefined;
}

const IntegrationId = ({label}: IntegrationId) => {
  const [field, meta, helpers] = useField<number | undefined>('integration_id');
  const {t} = useTranslation();

  const {integration} = label || {};
  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      <IntegrationSearchSelect
        {...field}
        domain={integration?.domain}
        isMaxWidth
        isValid={isValid}
        onChange={integration => helpers.setValue(integration.id)}
      />
    </div>
  );
};

export default IntegrationId;

import * as AppEnv from 'app-env';

const filterToRequest = (filter: AppEnv.LabelFilter): AppEnv.LabelRequest => ({
  chatId: filter.chatId ? {value: filter.chatId} : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  tag: filter.tag ? {value: filter.tag, type: 'like'} : undefined
});

export default filterToRequest;

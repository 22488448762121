import React from 'react';
import {
  Button,
  Line,
  Popup,
  SaveButton,
  Title as CoreTitle,
  Wrapper
} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import ChatId from './chat-id/chat-id';
import Color from './color/color';
import IntegrationId from './integration-id/integration-id';
import Tag from './tag/tag';

interface LabelForm {
  initialValues?: Partial<AppEnv.LabelExpand>;
  isActive: boolean;
  isUpdate?: boolean;
  onSubmit: AppEnv.Submit<Partial<AppEnv.LabelShape>>;
  label?: AppEnv.LabelExpand;
  setIsActive: AppEnv.SetState<boolean>;
}

const initialInitialValues = {
  chatId: '',
  color: '#34aadf',
  integration_id: undefined,
  tag: ''
};

const validationSchema = yup.object().shape({
  chatId: yup.string().trim().required(),
  color: yup.string().trim().required(),
  integration_id: yup.number().required(),
  tag: yup.string().trim().required()
});

const LabelForm = ({
  initialValues = initialInitialValues,
  isActive,
  isUpdate,
  onSubmit,
  label,
  setIsActive
}: LabelForm) => {
  const {t} = useTranslation();

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)}>
      <CoreTitle.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Edit the label` : t`Create a label`}
      </CoreTitle.H2>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Tag />
            <Color />
            <ChatId />
            <IntegrationId label={label} />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default LabelForm;

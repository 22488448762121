import React, {useState} from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import Collapsible from 'react-collapsible';

import Access from './access/access';

const Accesses = () => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  return (
    <div>
      <Button
        color="transparent"
        onClick={() => setOpen(prevValue => !prevValue)}
        style={{justifyContent: 'space-between', width: '100%'}}
      >
        {t`Permissions`}
        <Icons.AngleDown />
      </Button>

      <Collapsible open={open} transitionTime={200} trigger={<></>}>
        <Wrapper gap={16} isColumn style={{marginTop: 8}}>
          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Chats`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="dialogs_blacklist" label={t`Block`} />
              <Access keyof="dialogs_delete" label={t`Delete`} />
              <Access keyof="dialogs_manager" label={t`Assign responsible`} />
              <Access keyof="dialogs_pin" label={t`Pin`} />
              <Access keyof="dialogs_unread" label={t`Mark as read`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Messages`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="messages_delete" label={t`Delete`} />
              <Access keyof="messages_edit" label={t`Edit`} />
              <Access keyof="messages_file" label={t`Send files`} />
              <Access keyof="messages_forward" label={t`Forward`} />
              <Access keyof="messages_poll" label={t`Send poll`} />
              <Access keyof="messages_reaction" label={t`Reactions`} />
              <Access keyof="messages_reply" label={t`Reply`} />
              <Access keyof="messages_text" label={t`Send text message`} />
              <Access keyof="messages_voice" label={t`Send voice`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Billing`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="billings_read" label={t`Read`} />
              <Access keyof="billings_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Blocked chats`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="blacklists_read" label={t`Read`} />
              <Access keyof="blacklists_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Connections`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="instances_read" label={t`Read`} />
              <Access keyof="instances_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Integrator`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="integrations_read" label={t`Read`} />
              <Access keyof="integrations_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Staff`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="managers_read" label={t`Read`} />
              <Access keyof="managers_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Partner program`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="partners_read" label={t`Read`} />
              <Access keyof="partners_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Products`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="products_read" label={t`Read`} />
              <Access keyof="products_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Settings`}</Title.H3>
            <Access keyof="settings_read" label={t`Write`} />
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Templates`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="templates_read" label={t`Read`} />
              <Access keyof="templates_write" label={t`Write`} />
            </Wrapper>
          </div>

          <div>
            <Title.H3 style={{marginBottom: 8}}>{t`Widgets`}</Title.H3>

            <Wrapper gap="8-16">
              <Access keyof="widgets_read" label={t`Read`} />
              <Access keyof="widgets_write" label={t`Write`} />
            </Wrapper>
          </div>
        </Wrapper>
      </Collapsible>
    </div>
  );
};

export default Accesses;

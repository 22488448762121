import React from 'react';
import {Input, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const ChatId = () => {
  const [field, , helpers] = useField<string | undefined>('chatId');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Chat ID`}</Title.H3>

      <Input
        {...field}
        autoCapitalize="off"
        onChange={helpers.setValue}
        placeholder={t`Chat ID`}
      />
    </div>
  );
};

export default ChatId;

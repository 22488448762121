import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {InputColor} from 'components';

const Color = () => {
  const [field, meta, helpers] = useField<string>('color');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Color`}</Title.H3>

      <InputColor {...field} isValid={isValid} onChange={helpers.setValue} />
    </div>
  );
};

export default Color;

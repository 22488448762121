import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';

import * as AppEnv from 'app-env';
import {useAccesses} from './hooks';

interface Permit {
  keyof: keyof AppEnv.RoleAccesses;
  label: string;
}

const Access = ({keyof, label}: Permit) => {
  const [field, , helpers] = useField<string>('accesses');
  const accesses = useAccesses();

  const handleChange = (value: boolean) => {
    const [a, b] = keyof.split('_');
    const newAccesses: AppEnv.RoleAccesses = {...accesses};

    newAccesses[keyof] = value;

    if (b == 'read' && !value)
      newAccesses[`${a}_write` as keyof AppEnv.RoleAccesses] = false;
    else if (b == 'write' && value)
      newAccesses[`${a}_read` as keyof AppEnv.RoleAccesses] = true;

    const newValue = JSON.stringify(newAccesses);
    helpers.setValue(newValue);
  };

  const value = accesses[keyof] ?? true;

  return (
    <Checkbox {...field} label={label} onChange={handleChange} value={value} />
  );
};

export default Access;

import React from 'react';
import {Button, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {RoleEdit} from 'pages/roles/roles-content/components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useRolesContext} from 'pages/roles/roles-context';
import * as AppEnv from 'app-env';

interface RoleUpdate {
  isUpdateActive: boolean;
  role: AppEnv.Role;
  setIsUpdateActive: AppEnv.SetState<boolean>;
}

const RoleUpdate = ({isUpdateActive, role, setIsUpdateActive}: RoleUpdate) => {
  const {pushNotification} = useAppContext();
  const {setRoleList} = useRolesContext();

  const {putRoles} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.RoleShape>) => {
    const {data} = await putRoles(role.id, values);
    if (!data) return true;

    pushNotification(t`Role updated`);
    setIsUpdateActive(false);

    setRoleList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);
      if (index == undefined || index < 0) return prevValue;

      return update(prevValue, {[index]: {$set: data}});
    });

    return true;
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsUpdateActive(true)}
        tip={t`Edit`}
      >
        <Icons.Edit />
      </Button>

      <RoleEdit
        initialValues={role}
        isActive={isUpdateActive}
        isUpdate
        onSubmit={handleSubmit}
        role={role}
        setIsActive={setIsUpdateActive}
      />
    </TableCell>
  );
};

export default RoleUpdate;

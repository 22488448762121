import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import filterToRequest from './filter-to-request';
import LabelsContent from './labels-content/labels-content';
import LabelsContext from './labels-context';
import LabelsFilter from './labels-filter/labels-filter';

const Labels = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [labelList, setLabelList] = useState<AppEnv.LabelExpand[]>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.LabelFilter> = useFilter();

  const {fetchLabels} = useRequest();
  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));
  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Labels`} - Whatcrm Console`;
  }, []);

  const getLabelList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchLabels(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setIsLoading(false);
    setLabelList(data || []);
  };

  useEffect(() => {
    getLabelList();
  }, [filter, pagination.currentPage]);

  return (
    <LabelsContext.Provider
      value={{
        getLabelList,
        isFilterActive,
        labelList,
        pagination,
        setIsFilterActive,
        setLabelList
      }}
    >
      <LabelsContent />
      <LabelsFilter filter={filter} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </LabelsContext.Provider>
  );
};

export default Labels;

import React, {useId} from 'react';
import {Icons, Wrapper} from 'whatcrm-core';
import cn from 'classnames';

interface InputColor {
  isValid?: boolean;
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const InputColor = ({isValid = true, name, onChange, value}: InputColor) => {
  const id = useId();

  return (
    <label
      className={cn('input-color', {'input-color_error': !isValid})}
      htmlFor={id}
    >
      <input
        id={id}
        name={name}
        onChange={event => onChange(event.target.value)}
        type="color"
        value={value}
      />

      <Wrapper gap={8} noWrap>
        <div className="input-color__color" style={{background: value}} />
        <div className="input-color__vertical" />
      </Wrapper>

      <Icons.AngleDown />
    </label>
  );
};

export default InputColor;

import React, {useState} from 'react';
import {Button, Confirmation, Icons, TableCell, TableRow} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useClientsContext} from '../../clients-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import ClientForm from './client-form/client-form';

interface Client {
  client: AppEnv.Integration;
}

const Client = ({client}: Client) => {
  const {pushNotification} = useAppContext();
  const {crmList} = useWorkspaceContext();
  const {getClientList} = useClientsContext();

  const [isClientFormActive, setIsCientFormActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const deleteClient = async () => {
    const {data} = await putIntegrations(client.id, {partner_id: 0});

    if (data) {
      pushNotification(client.domain, t`The client has been deleted`);
      getClientList();
    }

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteClient();
    setIsConfirmationActive(false);
  };

  const crm = crmList.find(crm => crm.id == client.integration_type_id);
  const isActive = isClientFormActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        {!!client.date_partner &&
          moment.unix(client.date_partner).utc().format('L')}
      </TableCell>

      <TableCell>
        <Link
          to={`/billing/payments?filter_domain=${client.domain}&filter_integration_id=${client.id}`}
          state={{referrer: window.location.pathname}}
        >
          <Button color="transparent" isLink>
            {client.domain}
          </Button>
        </Link>
      </TableCell>

      <TableCell>{crm?.title}</TableCell>
      <TableCell>{client.instances_count}</TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          onClick={() => setIsCientFormActive(true)}
          tip={t`Edit`}
        >
          <Icons.Edit />
        </Button>

        <ClientForm
          client={client}
          isClientFormActive={isClientFormActive}
          setIsClientFormActive={setIsCientFormActive}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          onClick={() => setIsConfirmationActive(true)}
          tip={t`Delete`}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          isActive={isConfirmationActive}
          onClick={handleClick}
          title={t`Are you sure you want to delete this client?`}
        />
      </TableCell>
    </TableRow>
  );
};

export default Client;

import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Unicons from '@iconscout/react-unicons';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

const useGetFusedCrmList = (crmList: AppEnv.Crm[]) => {
  const getFusedCrmList = (isMain: -1 | 0 | 1) => {
    if (isMain == -1) return crmList;
    return crmList.filter(crm => crm.is_main == isMain);
  };

  return getFusedCrmList;
};

const usePages = () => {
  const {client, preferences} = useAppContext();
  const {t} = useTranslation();

  let allPages: AppEnv.Pages = {
    automations: {
      pathname: '/automations',
      label: t`Automations`,
      icon: <Unicons.UilRobot className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    billing: {
      pathname: '/billing',
      label: t`Billing`,
      icon: <Unicons.UilBill className="uilicon" />,
      access: ['PARTNER', 'MODER', 'SUPMODER', 'ADMIN', 'USER'],
      subpages: [
        {pathname: '/billing/payments', label: t`Payments`},
        {
          pathname: '/billing/cloudpayments',
          label: t`CloudPayments subscriptions`
        },
        {pathname: '/billing/stripe', label: t`Stripe subscriptions`},
        {pathname: '/billing/telphin', label: t`Telphin subscriptions`}
        // {pathname: '/billing/tinkoff', label: t`Tinkoff subscriptions`}
      ]
    },
    blockList: {
      pathname: '/block-list',
      label: t`Block list`,
      icon: <Unicons.UilCommentBlock className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    connections: {
      pathname: '/connections',
      label: t`Connections`,
      icon: <Unicons.UilLink className="uilicon" />,
      access: ['INTEGRATION', 'MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    contacts: {
      pathname: '/contacts',
      label: t`Contacts`,
      icon: <Unicons.UilDiary className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    crm: {
      pathname: '/crm',
      label: 'CRM',
      icon: <Unicons.UilBriefcaseAlt className="uilicon" />,
      access: ['SUPMODER', 'ADMIN', 'USER']
    },
    events: {
      pathname: '/events',
      label: t`Events`,
      icon: <Unicons.UilCalendarAlt className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    integrations: {
      pathname: '/integrations',
      label: t`Integrations`,
      icon: <Unicons.UilServerNetwork className="uilicon" />,
      access: [
        'INTEGRATION',
        'TELPHIN',
        'PARTNER',
        'MODER',
        'SUPMODER',
        'ADMIN',
        'USER'
      ]
    },
    invoices: {
      pathname: '/invoices',
      label: t`Invoices`,
      icon: <Unicons.UilReceiptAlt className="uilicon" />,
      access: ['INTEGRATION', 'ADMIN', 'USER']
    },
    labels: {
      pathname: '/labels',
      label: t`Labels`,
      icon: <Unicons.UilLabelAlt className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    notifications: {
      pathname: '/notifications',
      label: t`Notifications`,
      icon: <Unicons.UilBell className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    partners: {
      pathname: '/partners',
      label: t`Partners`,
      icon: <Unicons.UilUserArrows className="uilicon" />,
      access: ['PARTNER', 'MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    promoCodes: {
      pathname: '/promo-codes',
      label: t`Promo codes`,
      icon: <Unicons.UilPercentage className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    rates: {
      pathname: '/rates',
      label: t`Rates`,
      icon: <Unicons.UilShoppingBag className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    roles: {
      pathname: '/roles',
      label: t`Roles`,
      icon: <Unicons.UilMedal className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    servers: {
      pathname: '/servers',
      search: '?version=whatcrm',
      label: t`Servers`,
      icon: <Unicons.UilDatabase className="uilicon" />,
      access: ['SUPMODER', 'ADMIN', 'USER'],
      subpages: [
        {
          label: 'WhatsApp',
          pathname: '/servers?version=whatcrm',
          access: ['SUPMODER', 'ADMIN', 'USER']
        },
        {
          label: 'Telegram',
          pathname: '/servers?version=telegram',
          access: ['SUPMODER', 'ADMIN', 'USER']
        }
      ]
    },
    staff: {
      pathname: '/staff',
      label: t`Staff`,
      icon: <Unicons.UilHardHat className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    },
    statistics: {
      pathname: '/statistics',
      label: t`Statistics`,
      icon: <Unicons.UilAnalytics className="uilicon" />,
      access: ['PARTNER', 'MODER', 'SUPMODER', 'ADMIN', 'USER'],
      subpages: [
        {
          pathname: '/statistics/connections',
          label: t`Connections`,
          access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
        },
        {
          pathname: '/statistics/integrations',
          label: t`Integrations`,
          access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
        },
        {
          pathname: '/statistics/payments',
          label: t`Payments`,
          access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
        },
        {
          pathname: '/statistics/payment-geography',
          label: t`Geography of payments`,
          access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
        },
        {pathname: '/statistics/partners', label: t`Partners`}
      ]
    },
    systemOptions: {
      pathname: '/system-options',
      label: t`System options`,
      icon: <Unicons.UilBracketsCurly className="uilicon" />,
      access: ['SUPMODER', 'ADMIN', 'USER']
    },
    tokens: {
      pathname: '/tokens',
      label: t`Tokens`,
      icon: <Unicons.UilCoins className="uilicon" />,
      access: ['SUPMODER', 'ADMIN', 'USER']
    },
    users: {
      pathname: '/users',
      label: t`Users`,
      icon: <Unicons.UilUsersAlt className="uilicon" />,
      access: ['MODER', 'SUPMODER', 'ADMIN', 'USER']
    }
  };

  if (!preferences?.isMenuItemsAlphabetically) {
    const connections = allPages.connections;
    const integrations = allPages.integrations;

    delete allPages.connections;
    delete allPages.integrations;

    allPages = Object.assign({connections, integrations}, allPages);
  }

  const pages: AppEnv.Pages = Object.fromEntries(
    Object.entries(allPages).filter(([, item]) =>
      client?.role ? item.access.includes(client.role) : false
    )
  );

  return pages;
};

export {useGetFusedCrmList, usePages};

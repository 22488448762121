import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRolesContext} from 'pages/roles/roles-context';
import Role from './role/role';

const RoleList = () => {
  const {roleList} = useRolesContext();
  const {t} = useTranslation();

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{t`Name`}</TableCell>
        <TableCell>{t`Integration`}</TableCell>
        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {roleList?.map(role => <Role key={role.id} role={role} />)}
      </TableBody>
    </Table>
  );
};

export default RoleList;

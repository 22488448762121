import React, {useState, useEffect} from 'react';
import {Title, Input, Preloader, EmptyBox} from 'whatcrm-core';
import * as Unicons from '@iconscout/react-unicons';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Details} from 'components';
import {useRequest} from 'common/hooks';

interface Stats {
  ct_count: number;
  ct_rub: number;
  stripe_count: number;
  stripe_rub: number | null;
  stripe_usd: number | null;
}

const PartnersOne = () => {
  const [max, setMax] = useState<string>();
  const [month, setMonth] = useState<string>();
  const [stats, setStats] = useState<Stats | null>();

  const {fetchStatistics} = useRequest();
  const {i18n, t} = useTranslation();

  const getStats = async () => {
    const start = moment.utc(month).startOf('month').format('DD.MM.yyyy');
    const end = moment.utc(month).endOf('month').format('DD.MM.yyyy');

    const {data} = await fetchStatistics<Stats>('partners_one', {end, start});

    setStats(data || null);
  };

  useEffect(() => {
    if (month) {
      setStats(undefined);
      getStats();
    }
  }, [month]);

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getUTCMonth() - 1);

    const value = moment.utc(date).format('yyyy-MM');

    setMax(value);
    setMonth(value);
  }, []);

  const date = new Date();
  date.setMonth(date.getUTCMonth() - 1);

  const details = stats
    ? [
        {
          icon: <Unicons.UilReceipt className="uilicon" />,
          value: (stats.ct_rub + (stats.stripe_rub || 0)).toLocaleString(
            i18n.language,
            {
              style: 'currency',
              currency: 'RUB'
            }
          )
        },
        {
          icon: <Unicons.UilBill className="uilicon" />,
          value: (stats.stripe_usd || 0).toLocaleString(i18n.language, {
            style: 'currency',
            currency: 'USD'
          })
        }
      ]
    : undefined;

  return (
    <div>
      <Title.H2 style={{marginBottom: 8}}>{t`First payments`}</Title.H2>

      <Input
        max={max}
        name="month"
        type="month"
        value={month}
        style={{marginBottom: 16}}
        onChange={setMonth}
      />

      {typeof stats == 'object' ? (
        details ? (
          <Details details={details} />
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default PartnersOne;

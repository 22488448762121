import React from 'react';
import {
  Button,
  Line,
  Popup,
  SaveButton,
  Title as CoreTitle,
  Wrapper
} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import About from './about/about';
import ChatId from './chat-id/chat-id';
import Email from './email/email';
import Fullname from './fullname/fullname';
import IntegrationId from './integration-id/integration-id';
import Phones from './phones/phones';
import Username from './username/username';
import Website from './website/website';

interface ContactForm {
  contact?: AppEnv.Contact;
  initialValues?: Partial<AppEnv.ContactShape>;
  isActive: boolean;
  isUpdate?: boolean;
  onSubmit: AppEnv.Submit<Partial<AppEnv.ContactShape>>;
  setIsActive: AppEnv.SetState<boolean>;
}

interface DefaultValues extends Omit<AppEnv.ContactShape, 'integration_id'> {
  integration_id: number | undefined;
}

const defaultValues: DefaultValues = {
  about: '',
  chatId: '',
  email: '',
  fullname: '',
  integration_id: undefined,
  phones: '[]',
  username: '',
  website: ''
};
const validationSchema = yup.object().shape({
  chatId: yup.string().trim().required(),
  integration_id: yup.number().required()
});

const ContactForm = ({
  contact,
  initialValues = defaultValues,
  isActive,
  isUpdate,
  onSubmit,
  setIsActive
}: ContactForm) => {
  const {t} = useTranslation();

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)}>
      <CoreTitle.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Edit the contact` : t`New contact`}
      </CoreTitle.H2>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Fullname />
            <Phones />
            <Username />
            <Email />
            <Website />
            <About />
            <ChatId />
            <IntegrationId contact={contact} />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default ContactForm;

// fullname
// phones
// username
// email
// website
// about

import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface RolesContext {
  getRoleList: () => Promise<void>;
  isFilterActive: boolean;
  pagination: CoreEnv.Pagination;
  roleList: AppEnv.RoleExpand[] | undefined;
  setIsFilterActive: AppEnv.SetState<boolean>;
  setRoleList: AppEnv.SetState<AppEnv.RoleExpand[] | undefined>;
}

const RolesContext = React.createContext<RolesContext>({} as RolesContext);

export const useRolesContext = () => useContext(RolesContext);
export default RolesContext;

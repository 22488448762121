import React from 'react';
import {EmptyBox, Pagination, Preloader, Wrapper} from 'whatcrm-core';

import {EntriesNumber} from 'components';
import {useContactsContext} from '../contacts-context';

import ContactList from './contact-list/contact-list';
import ContactsHeader from './contacts-header/contacts-header';

const ContactsContent = () => {
  const {contactList, pagination} = useContactsContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <ContactsHeader />

        {contactList ? (
          contactList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <ContactList />
              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default ContactsContent;

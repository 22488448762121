import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {ContactForm} from '../../components';
import {useAppContext} from 'app-context';
import {useContactsContext} from 'pages/contacts/contacts-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const ContactCreate = () => {
  const {pushNotification} = useAppContext();
  const {getContactList} = useContactsContext();

  const [isActive, setIsActive] = useState(false);

  const {postContacts} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.ContactShape>) => {
    const {data} = await postContacts(values);
    if (!data) return true;

    getContactList();
    pushNotification(t`Contact created`);
    setIsActive(false);

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <ContactForm
        isActive={isActive}
        onSubmit={handleSubmit}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default ContactCreate;

import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import Client from './client/client';

interface ClientList {
  clientList: AppEnv.Integration[];
}

const ClientList = ({clientList}: ClientList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Date added`}</TableCell>
        <TableCell>{t`Domain`}</TableCell>
        <TableCell>{'CRM'}</TableCell>
        <TableCell>{t`Connections`}</TableCell>
        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {clientList.map(client => (
          <Client client={client} key={client.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ClientList;

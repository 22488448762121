import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import ContactsContent from './contacts-content/contacts-content';
import ContactsContext from './contacts-context';
import ContactsFilter from './contacts-filter/contacts-filter';
import filterToRequest from './filter-to-request';

const Contacts = () => {
  const [contactList, setContactList] = useState<AppEnv.Contact[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchContacts} = useRequest();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.ContactFilter> = useFilter();

  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));
  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Contacts`} - Whatcrm Console`;
  }, []);

  const getContactList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchContacts(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setContactList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getContactList();
  }, [filter, pagination.currentPage]);

  return (
    <ContactsContext.Provider
      value={{
        contactList,
        getContactList,
        isFilterActive,
        pagination,
        setContactList,
        setIsFilterActive
      }}
    >
      <ContactsContent />
      <ContactsFilter filter={filter} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </ContactsContext.Provider>
  );
};

export default Contacts;

import React from 'react';

import {Sidebar} from 'components';
import {useContactsContext} from '../contacts-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import About from './about/about';
import ChatId from './chat-id/chat-id';
import Domain from './domain/domain';
import Email from './email/email';
import Fullname from './fullname/fullname';
import Phones from './phones/phones';
import Username from './username/username';
import Website from './website/website';

interface Filter {
  filter: AppEnv.ContactFilter;
  setFilter: AppEnv.SetState<AppEnv.ContactFilter>;
}

interface Values extends Partial<AppEnv.ContactFilter> {
  domain?: string;
}

const ContactsFilter = ({filter, setFilter}: Filter) => {
  const {isFilterActive, contactList, setIsFilterActive} = useContactsContext();
  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: Values) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={{}}
      isActive={isFilterActive}
      isDisabled={!contactList}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
      onSubmit={handleSubmit}
    >
      <Fullname />
      <Phones />
      <Username />
      <Email />
      <Website />
      <About />
      <ChatId />
      <Domain />
    </Sidebar>
  );
};

export default ContactsFilter;

import React from 'react';
import {Button, Confirmation, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useLabelsContext} from 'pages/labels/labels-context';
import * as AppEnv from 'app-env';

interface LabelDelete {
  isConfirmActive: boolean;
  label: AppEnv.LabelExpand;
  setIsConfirmActive: AppEnv.SetState<boolean>;
}

const LabelDelete = ({
  isConfirmActive,
  label,
  setIsConfirmActive
}: LabelDelete) => {
  const {pushNotification} = useAppContext();
  const {getLabelList} = useLabelsContext();

  const {deleteLabels} = useRequest();
  const {t} = useTranslation();

  const deleteLabel = async () => {
    const {data} = await deleteLabels(label.id);
    if (typeof data != 'string') return true;

    getLabelList();
    pushNotification(t`Label deleted`);
    setIsConfirmActive(false);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteLabel();
    setIsConfirmActive(false);
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsConfirmActive(true)}
        tip={t`Delete`}
      >
        <Icons.Trash />
      </Button>

      <Confirmation
        isActive={isConfirmActive}
        onClick={handleClick}
        title={t`Delete this label?`}
      />
    </TableCell>
  );
};

export default LabelDelete;

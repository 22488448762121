import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DatePartner = () => {
  const [field, , helpers] = useField<number | null>('date_partner');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(+moment(new Date(value)).utc().format('X'));

  const value = field.value
    ? moment.unix(field.value).utc().format('YYYY-MM-DD')
    : undefined;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Date added`}</Title.H3>

      <Input
        {...field}
        onChange={handleChange}
        type="date"
        value={value}
        width="max"
      />
    </div>
  );
};

export default DatePartner;

import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import filterToRequest from './filter-to-request';
import RolesContent from './roles-content/roles-content';
import RolesContext from './roles-context';
import RolesFilter from './roles-filter/roles-filter';

const Roles = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [roleList, setRoleList] = useState<AppEnv.RoleExpand[]>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.RoleFilter> = useFilter();

  const {fetchRoles} = useRequest();
  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));
  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Roles`} - Whatcrm Console`;
  }, []);

  const getRoleList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchRoles(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setIsLoading(false);
    setRoleList(data || []);
  };

  useEffect(() => {
    getRoleList();
  }, [filter, pagination.currentPage]);

  return (
    <RolesContext.Provider
      value={{
        getRoleList,
        isFilterActive,
        pagination,
        roleList,
        setIsFilterActive,
        setRoleList
      }}
    >
      <RolesContent />
      <RolesFilter filter={filter} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </RolesContext.Provider>
  );
};

export default Roles;

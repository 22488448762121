import React from 'react';
import {Checkbox, Text, Title, Tooltip, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Icons} from 'components';
import {useConnectionContext} from '../connection-context';
import {useHomeContext} from 'pages/connections/home/home-context';

import ActionMenu from './action-menu/action-menu';

const Header = () => {
  const {selectedConnectionIdList, setSelectedConnectionIdList} =
    useHomeContext();

  const {connection} = useConnectionContext();
  const {t} = useTranslation();

  const handleChange = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedConnectionIdList(prevValue =>
        update(prevValue, {
          $push: [connection.id]
        })
      );

      return;
    }

    setSelectedConnectionIdList(prevValue =>
      prevValue?.includes(connection.id)
        ? update(prevValue, {
            $splice: [[prevValue.indexOf(connection.id), 1]]
          })
        : prevValue
    );
  };

  return (
    <Wrapper justifyContent="space-between" noWrap>
      <Wrapper alignItems="center" gap={8} noWrap style={{minWidth: 0}}>
        {connection.version == 'avito' ? (
          <Icons.Avito />
        ) : connection.version == 'chat' ? (
          <Icons.Chat />
        ) : connection.version == 'cloud' ? (
          <Icons.Meta />
        ) : connection.version == 'telegram' ? (
          <Icons.Telegram />
        ) : (
          connection.version == 'whatcrm' && <Icons.WhatsApp />
        )}

        <Title.H2 direction="rtl" noWrap>
          {t`Connection`} {connection.id}
        </Title.H2>
      </Wrapper>

      <Wrapper
        alignItems="center"
        justifyContent="center"
        noWrap
        style={{margin: '-8px -8px 0 0'}}
      >
        {connection.is_deleted == 1 && (
          <Tooltip isWarning>
            <Text color="dark" isParagraph size="l">
              {t`The connection is off.`}
            </Text>
          </Tooltip>
        )}

        {selectedConnectionIdList ? (
          <Checkbox
            onChange={handleChange}
            value={selectedConnectionIdList.includes(connection.id)}
          />
        ) : (
          <ActionMenu />
        )}
      </Wrapper>
    </Wrapper>
  );
};

export default Header;

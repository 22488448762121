import React, {useState, useEffect} from 'react';
import {Button, Title, Input, Wrapper, Preloader, EmptyBox} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Details} from 'components';
import {usePartnerContext} from '../../partner-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Payment {
  icon: React.ReactNode;
  label: React.ReactNode;
  value: React.ReactNode;
}

interface Payments {
  summary: Payment[];
  cloudpayment: Payment[];
  stripe: Payment[];
  telphin: Payment[];
}

const Payments = () => {
  const {partner} = usePartnerContext();

  const [max, setMax] = useState<string>();
  const [month, setMonth] = useState<string>();

  const [payments, setPayments] = useState<Payments | null>();

  const {fetchPayments} = useRequest();
  const {i18n, t} = useTranslation();

  const getDetails = (paymentSystem: AppEnv.PartnerPaymentSystem) => [
    {
      icon: <Unicons.UilInvoice className="uilicon" />,
      label: t`payment amount`,
      value: (paymentSystem.clients || 0).toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'RUB'
      })
    },
    {
      icon: <Unicons.UilReceipt className="uilicon" />,
      label: t`partner payment amount`,
      value: (paymentSystem.partner || 0).toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'RUB'
      })
    }
  ];

  const getPayments = async () => {
    const start = moment.utc(month).startOf('month');
    const end = moment.utc(month).endOf('month');

    const {data} = await fetchPayments<AppEnv.PartnerPayments>({
      end: end.format('DD.MM.yyyy'),
      id: partner.id,
      start: start.format('DD.MM.yyyy')
    });

    if (data) {
      const dateYslugAfter = start.format('yyyy-MM-DD');
      const dateYslugBefore = end.format('yyyy-MM-DD');
      const integrationId = data.clients.join(',');

      setPayments({
        summary: [
          {
            icon: (
              <Unicons.UilMoneyBillStack
                className="uilicon"
                style={{paddingBottom: 0}}
              />
            ),
            label: (
              <Link
                to={`/billing/payments?filter_date_yslug_after=${dateYslugAfter}&filter_date_yslug_before=${dateYslugBefore}&filter_integration_id=${integrationId}`}
                state={{
                  referrer: window.location.pathname + window.location.search
                }}
              >
                <Button color="transparent" isLink style={{fontSize: '.75rem'}}>
                  {t`total payments`}
                </Button>
              </Link>
            ),
            value: data.count_all
          },
          {
            icon: (
              <Unicons.UilMoneyStack
                className="uilicon"
                style={{paddingBottom: 0}}
              />
            ),
            label: t`total partner payments`,
            value: data.count_partner
          },
          {
            icon: (
              <Unicons.UilMoneyWithdraw
                className="uilicon"
                style={{paddingTop: 0}}
              />
            ),
            label: t`partner’s income`,
            value: data.income.toLocaleString(i18n.language, {
              style: 'currency',
              currency: 'RUB'
            })
          }
        ],
        cloudpayment: getDetails(data.cloudpayment),
        stripe: getDetails(data.stripe),
        telphin: getDetails(data.telphin)
      });
      return;
    }

    setPayments(null);
  };

  useEffect(() => {
    if (month) {
      getPayments();
    }
  }, [month]);

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getUTCMonth() - 1);

    const value = moment.utc(date).format('yyyy-MM');

    setMax(value);
    setMonth(value);
  }, []);

  return (
    <div>
      <Title.H2 style={{marginBottom: 8}}>{t`Payments`}</Title.H2>

      <Input
        max={max}
        name="month"
        type="month"
        value={month}
        style={{marginBottom: 16}}
        onChange={setMonth}
      />

      {typeof payments == 'object' ? (
        payments ? (
          <Wrapper gap={16}>
            <div>
              <Title.H3 style={{marginBottom: 8}}>{t`Summary`}</Title.H3>
              <Details details={payments.summary} />
            </div>

            <Wrapper gap={16}>
              <div>
                <Title.H3 style={{marginBottom: 8}}>{'CloudPayments'}</Title.H3>
                <Details details={payments.cloudpayment} />
              </div>

              <div>
                <Title.H3 style={{marginBottom: 8}}>{'Stripe'}</Title.H3>
                <Details details={payments.stripe} />
              </div>

              <div>
                <Title.H3 style={{marginBottom: 8}}>{t`Telphin`}</Title.H3>
                <Details details={payments.telphin} />
              </div>
            </Wrapper>
          </Wrapper>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default Payments;

import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {LabelForm} from '../../components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useLabelsContext} from 'pages/labels/labels-context';
import * as AppEnv from 'app-env';

const LabelCreate = () => {
  const {pushNotification} = useAppContext();
  const {getLabelList} = useLabelsContext();

  const [isActive, setIsActive] = useState(false);

  const {postLabels} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.LabelShape>) => {
    const {data} = await postLabels(values);
    if (!data) return true;

    getLabelList();
    pushNotification(t`Label created`);
    setIsActive(false);

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <LabelForm
        isActive={isActive}
        onSubmit={handleSubmit}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default LabelCreate;

import React from 'react';
import {Input, Title as CoreTitle} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Tag = () => {
  const [field, , helpers] = useField<string | undefined>('tag');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Tag`}</CoreTitle.H3>
      <Input {...field} onChange={helpers.setValue} placeholder={t`Tag`} />
    </div>
  );
};

export default Tag;

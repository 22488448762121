import React from 'react';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Domain from './domain/domain';
import Fullname from './fullname/fullname';
import Email from './email/email';
import Phone from './phone/phone';
import {useStaffContext} from '../staff-context';

interface Filter {
  setFilter: AppEnv.SetState<AppEnv.EmployeeFilter>;
}

interface Values extends AppEnv.EmployeeFilter {
  domain?: string;
}

const Filter = ({setFilter}: Filter) => {
  const {employeeList, filter, isFilterActive, setIsFilterActive} =
    useStaffContext();

  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: Values) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  return (
    <Sidebar
      initialValues={filter}
      isActive={isFilterActive}
      isDisabled={!employeeList}
      onSubmit={handleSubmit}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <Fullname />
      <Email />
      <Phone />
      <Domain />
    </Sidebar>
  );
};

export default Filter;

import React from 'react';
import {Text} from 'whatcrm-core';

interface Tag {
  children: React.ReactNode;
  color: string;
}

const Tag = ({children, color}: Tag) => (
  <div className="tag" style={{background: color}}>
    <Text color="white">{children}</Text>
  </div>
);

export default Tag;

import React, {useState} from 'react';
import {Line, Wrapper, Button, Anchor} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';
import {useRequest} from 'common/hooks';

import Screenshot from './screenshot/screenshot';
import Status from './status/status';

interface ScreenshotSuccess {
  screenshot: string;
}

interface StatusSuccess {
  accountStatus: string;
  state: string;
  statusData: [];
}

const Actions = () => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const [isRebooting, setIsRebooting] = useState(false);
  const [isScreenshoting, setIsScreenshoting] = useState(false);
  const [isStatusLoad, setIsStatusLoad] = useState(false);
  const [screenshot, setScreenshot] = useState<string>();
  const [status, setStatus] = useState<string>();

  const {updateInstance} = useRequest();
  const {t} = useTranslation();

  const reboot = async () => {
    setIsRebooting(true);

    const {data} = await updateInstance<{success: 1 | 'ok'}>(
      connection.chat_key,
      'reboot'
    );

    if (data) {
      pushNotification(
        `${t`Connection`} ${connection.id}`,
        t`The connection has been rebooted`
      );
    }

    setIsRebooting(false);
  };

  const getScreenshot = async () => {
    setIsScreenshoting(true);

    const {data} = await updateInstance<ScreenshotSuccess>(
      connection.chat_key,
      'screenshot'
    );

    setIsScreenshoting(false);
    if (!data) return;

    setScreenshot(data.screenshot);
  };

  const handleStatus = async () => {
    setIsStatusLoad(true);

    const {data} = await updateInstance<StatusSuccess>(
      connection.chat_key,
      'status'
    );

    setStatus(data?.state);
    setIsStatusLoad(false);
  };

  return (
    <>
      <div>
        <Line color={connection.is_deleted ? 'accent' : undefined} />

        <Wrapper gap={8}>
          <Link
            to={`/events?filter_category=instances&filter_category_id=${connection.id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="white" tip={t`Open events`}>
              <Unicons.UilCalendarAlt className="core-icon uilicon" />
            </Button>
          </Link>

          <Link
            to={`/connections/${connection.id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="white" tip={t`Open the payments`}>
              <Unicons.UilTransaction className="core-icon uilicon" />
            </Button>
          </Link>

          <Anchor
            href={`${process.env.REACT_APP_APP}/scanner/${connection.chat_key}`}
            target="_blank"
            isDisabled={connection.status == 'READY'}
          >
            <Button color="white" tip={t`Open the QR code`}>
              <Unicons.UilQrcodeScan className="core-icon uilicon" />
            </Button>
          </Anchor>

          <Button
            color="white"
            tip={t`Reboot`}
            isDisabled={isRebooting}
            onClick={reboot}
          >
            <Unicons.UilRedo className="core-icon uilicon" />
          </Button>

          {connection.version == 'whatcrm' && (
            <Button
              color="white"
              tip={t`Take a screenshot`}
              isDisabled={isScreenshoting}
              onClick={getScreenshot}
            >
              <Unicons.UilCapture className="core-icon uilicon" />
            </Button>
          )}

          <Button
            color="white"
            isDisabled={isStatusLoad}
            onClick={handleStatus}
            tip={t`View status`}
          >
            <Unicons.UilHeartbeat className="core-icon uilicon" />
          </Button>
        </Wrapper>
      </div>

      <Screenshot
        screenshot={screenshot}
        isScreenshoting={isScreenshoting}
        setScreenshot={setScreenshot}
        getScreenshot={getScreenshot}
      />

      <Status setStatus={setStatus} status={status} />
    </>
  );
};

export default Actions;

import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useLabelsContext} from 'pages/labels/labels-context';
import Label from './label/label';

const LabelList = () => {
  const {labelList} = useLabelsContext();
  const {t} = useTranslation();

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{t`Tag`}</TableCell>
        <TableCell>{t`Chat ID`}</TableCell>
        <TableCell>{t`Integration`}</TableCell>
        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {labelList?.map(label => <Label key={label.id} label={label} />)}
      </TableBody>
    </Table>
  );
};

export default LabelList;

import React from 'react';
import {Button, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {ContactForm} from 'pages/contacts/contacts-content/components';
import {useAppContext} from 'app-context';
import {useContactsContext} from 'pages/contacts/contacts-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ContactUpdate {
  contact: AppEnv.Contact;
  isUpdateActive: boolean;
  setIsUpdateActive: AppEnv.SetState<boolean>;
}

const ContactUpdate = ({
  contact,
  isUpdateActive,
  setIsUpdateActive
}: ContactUpdate) => {
  const {pushNotification} = useAppContext();
  const {setContactList} = useContactsContext();

  const {putContacts} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Partial<AppEnv.ContactShape>) => {
    const {data} = await putContacts(contact.id, values);
    if (!data) return true;

    pushNotification(t`Contact updated`);

    setContactList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);
      if (index == undefined || index < 0) return prevValue;

      return update(prevValue, {[index]: {$set: data}});
    });

    setIsUpdateActive(false);
    return true;
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsUpdateActive(true)}
        tip={t`Edit`}
      >
        <Icons.Edit />
      </Button>

      <ContactForm
        contact={contact}
        initialValues={contact}
        isActive={isUpdateActive}
        isUpdate
        onSubmit={handleSubmit}
        setIsActive={setIsUpdateActive}
      />
    </TableCell>
  );
};

export default ContactUpdate;

import React from 'react';
import {Button, Confirmation, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useRolesContext} from 'pages/roles/roles-context';
import * as AppEnv from 'app-env';

interface RoleDelete {
  isConfirmActive: boolean;
  role: AppEnv.Role;
  setIsConfirmActive: AppEnv.SetState<boolean>;
}

const RoleDelete = ({
  isConfirmActive,
  role,
  setIsConfirmActive
}: RoleDelete) => {
  const {pushNotification} = useAppContext();
  const {getRoleList} = useRolesContext();

  const {deleteRoles} = useRequest();
  const {t} = useTranslation();

  const deleteRole = async () => {
    const {data} = await deleteRoles(role.id);
    if (typeof data != 'string') return true;

    getRoleList();
    pushNotification(t`Role deleted`);
    setIsConfirmActive(false);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteRole();
    setIsConfirmActive(false);
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsConfirmActive(true)}
        tip={t`Delete`}
      >
        <Icons.Trash />
      </Button>

      <Confirmation
        isActive={isConfirmActive}
        onClick={handleClick}
        title={t`Delete this role?`}
      />
    </TableCell>
  );
};

export default RoleDelete;

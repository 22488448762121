import React from 'react';
import {Route, Routes} from 'react-router-dom';

import Home from './home/home';
import Partner from './partner/partner';

const Partners = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path=":id/*" element={<Partner />} />
  </Routes>
);

export default Partners;

import React from 'react';
import {EmptyBox, Pagination, Preloader, Wrapper} from 'whatcrm-core';

import {EntriesNumber} from 'components';
import {useLabelsContext} from '../labels-context';

import LabelList from './label-list/label-list';
import LabelsHeader from './labels-header/labels-header';

const LabelsContent = () => {
  const {pagination, labelList} = useLabelsContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <LabelsHeader />

        {labelList ? (
          labelList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <LabelList />
              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default LabelsContent;

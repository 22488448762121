import React from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useLabelsContext} from 'pages/labels/labels-context';
import * as AppEnv from 'app-env';

import LabelCreate from './label-create/label-create';

interface State {
  referrer?: string;
}

const LabelsHeader = () => {
  const {isFilterActive, setIsFilterActive} = useLabelsContext();

  const {state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  return (
    <>
      {state?.referrer && (
        <Link to={state.referrer}>
          <Button color="white">
            <Icons.AngleLeft />
            {t`Back`}
          </Button>
        </Link>
      )}

      <Title.H1>{t`Labels`}</Title.H1>

      <Wrapper gap={8}>
        <LabelCreate />

        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>
      </Wrapper>
    </>
  );
};

export default LabelsHeader;

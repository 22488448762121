import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ClientsContext {
  getClientList: () => void;
  setClientList: AppEnv.SetState<AppEnv.Integration[] | undefined>;
}

const ClientsContext = React.createContext<ClientsContext>(
  {} as ClientsContext
);

export const useClientsContext = () => useContext(ClientsContext);
export default ClientsContext;

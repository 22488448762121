import * as AppEnv from 'app-env';

const filterToRequest = (filter: AppEnv.RoleFilter): AppEnv.RoleRequest => ({
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  title: filter.title ? {value: filter.title, type: 'like'} : undefined
});

export default filterToRequest;

import React, {useEffect, useState} from 'react';
import {EmptyBox, Preloader, Substrate, Wrapper} from 'whatcrm-core';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Clients from './clients/clients';
import Header from './header/header';
import Integrations from './integrations/integrations';
import PartnerContext from './partner-context';

const Partner = () => {
  const [partner, setPartner] = useState<AppEnv.Partner | null>();

  const {fetchPartners} = useRequest();
  const {id} = useParams();
  const {t} = useTranslation();

  useEffect(() => {
    const title = partner?.fullname || t`Partner`;
    document.title = `${title} - Whatcrm Console`;
  }, [partner?.fullname]);

  const getPartner = async () => {
    if (!id) return setPartner(null);

    const {data} = await fetchPartners<AppEnv.Partner>({id: +id});
    setPartner(data || null);
  };

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <div className="content">
      <Wrapper gap={8} isColumn>
        <Header />

        <Substrate>
          {partner != undefined ? (
            partner ? (
              <PartnerContext.Provider value={{partner, setPartner}}>
                <Routes>
                  <Route path="clients" element={<Clients />} />
                  <Route path="integrations" element={<Integrations />} />
                  <Route path="/" element={<Navigate to="clients" />} />
                </Routes>
              </PartnerContext.Provider>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Substrate>
      </Wrapper>
    </div>
  );
};

export default Partner;

import React from 'react';
import {Button, Confirmation, Icons, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useContactsContext} from 'pages/contacts/contacts-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ContactDelete {
  contact: AppEnv.Contact;
  isConfirmActive: boolean;
  setIsConfirmActive: AppEnv.SetState<boolean>;
}

const ContactDelete = ({
  contact,
  isConfirmActive,
  setIsConfirmActive
}: ContactDelete) => {
  const {pushNotification} = useAppContext();
  const {getContactList} = useContactsContext();

  const {deleteContacts} = useRequest();
  const {t} = useTranslation();

  const deleteContact = async () => {
    const {data} = await deleteContacts(contact.id);
    if (typeof data != 'string') return true;

    getContactList();
    pushNotification(t`Contact deleted`);
    setIsConfirmActive(false);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteContact();
    setIsConfirmActive(false);
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        onClick={() => setIsConfirmActive(true)}
        tip={t`Delete`}
      >
        <Icons.Trash />
      </Button>

      <Confirmation
        isActive={isConfirmActive}
        onClick={handleClick}
        title={t`Delete this contact?`}
      />
    </TableCell>
  );
};

export default ContactDelete;
